import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, H6, P, Image } from "../../AbstractElements";
import logoWhite from "../../assets/images/logo/logo_red.svg";
import logoDark from "../../assets/images/logo/logo_white.svg";
import { AtSign, Smartphone } from "react-feather";
import TailChase from "../../Components/Loaders/TailChase";
import {fetchCustomizationSettings} from "../../utils/cutomization";

const ForgetPwd = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [showEmail, setShowEmail] = useState(true);
  const [showPhone, setShowPhone] = useState(false);

  const [customization, setCustomization] = useState({
    color1: '#000000',
    color2: '#FFFFFF',
    bgImage: 'default-bg.jpg',
    logo: 'default-logo.png'
  });

  useEffect(() => {
    async function loadCustomization() {
      const settings = await fetchCustomizationSettings();
      setCustomization(settings);
      document.documentElement.style.setProperty("--theme-default", settings.color1);
      document.documentElement.style.setProperty("--theme-secondary", settings.color2);
    }
    loadCustomization();
  }, []);


  const handeForgetPwd = async (e) => {
    e.preventDefault();
    if (showEmail) {
      // handle email submission
      console.log(emailValue);
    } else if (showPhone) {
      // handle phone submission
      console.log(phoneValue);
    }
  };
  return (
    <Fragment>
      <section>
        <Container
            className="p-0 login-page"
            fluid={true}
            style={{
              backgroundImage: `url(${customization.bgImage})`,
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
        >
          <Row className="m-0">
            <Col className="p-0">
              <div className="login-card">
                <div>
                  <div>
                    <Link
                      className={`logo ${logoClassMain ? logoClassMain : ""}`}
                      to={process.env.PUBLIC_URL}
                    >
                      <Image
                          attrImage={{
                            className: "img-fluid for-light",
                            src: customization.logo,
                            alt: "loginpage",
                            style: { width: '300px' }
                          }}
                      />
                    </Link>
                  </div>
                  <div className="login-main">
                    <Form
                      className="theme-form login-form"
                      onSubmit={handeForgetPwd}
                    >
                      <H4 attrH4={{ className: "mb-3" }}>
                        Reset Your Password
                      </H4>

                      {/* <H6 attrH6={{ className: "mt-3 mb-3" }}>
                        Reset Your Password By Email 
                      </H6> */}

                      {/* <Row>
  
                        <Col
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Btn
                            attrBtn={{
                              className:
                                "btn-pill btn-air-primary btn-square  btn-sm",
                              color: "primary",
                              type: "button",
                              size: "sm",
                              outline: true,

                              onClick: (e) => {
                                setShowEmail(!showEmail);
                                if (!showEmail) setShowPhone(false);
                              },
                            }}
                          >
                            <AtSign size={15} />{" "}
                          </Btn>
                        </Col>
             
                        <Col
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Btn
                            attrBtn={{
                              className:
                                "btn-pill btn-air-primary btn-square  btn-sm",
                              color: "primary",
                              type: "button",
                              size: "sm",
                              outline: true,
                              // active: btnItem.active ? true : false,
                              // disabled: btnItem.disabled ? true : false,
                              // outline: btnItem.outline ? true : false,
                              // onClick={() => setShowEmail(!showEmail)}
                              onClick: (e) => {
                                setShowPhone(!showPhone);
                                if (!showPhone) setShowEmail(false);
                              },
                            }}
                          >
                            <Smartphone size={15} />{" "}
                          </Btn>
                        </Col>
                      </Row> */}
                      {/* SMS Form */}
                      {showPhone && (
                        <>
                          <FormGroup>
                            <Label className="m-0 col-form-label">
                              <Smartphone /> Enter Your Mobile Number
                            </Label>
                            <Row>
                              <Col xs="4" sm="3">
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="+ 216"
                                />
                              </Col>
                              <Col xs="8" sm="9">
                                <Col xs="8" sm="9">
                                  <Input
                                    className="form-control"
                                    type="tel"
                                    placeholder="00-00-00-00"
                                    value={phoneValue}
                                    onChange={(e) =>
                                      setPhoneValue(e.target.value)
                                    }
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="text-end">
                            <Btn
                              attrBtn={{
                                className: "btn-block",
                                color: "primary",
                                type: "submit",
                              }}
                            >
                              Send
                            </Btn>
                          </FormGroup>
                        </>
                      )}
                      {/* EMAIL Form */}
                      {showEmail && (
                        <>
                          <FormGroup className="position-relative">
                            <Label className="col-form-label m-0">
                              Enter Your Email
                            </Label>
                            <Row>
                              <Col xs="12">
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter your Email"
                                  value={emailValue}
                                  onChange={(e) =>
                                    setEmailValue(e.target.value)
                                  }
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="text-end">
                            <Btn
                              attrBtn={{
                                className: "btn-block ",
                                color: "primary",
                                type: "submit",
                              }}
                            >
                              Send
                            </Btn>
                          </FormGroup>
                        </>
                      )}
                      {/* <FormGroup className="text-end">
                        <Btn
                          attrBtn={{
                            className: "btn-block ",
                            color: "primary",
                            type: "submit",
                          }}
                        >
                          Send
                        </Btn>
                      </FormGroup> */}

                      <P attrPara={{ className: "text-start" }}>
                        Already have a password?
                        <Link
                          to={`${process.env.PUBLIC_URL}/login`}
                          className="ms-2"
                        >
                          Sign in
                        </Link>
                      </P>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ForgetPwd;
