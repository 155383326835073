export const MENUITEMS = {

  admin: [
    {
      menutitle: "General",
      menucontent: "Dashboards,Users,Widgets,Labels,Releases,DDEX,Analitycs,Tools,Accounting",
      Items: [
        {
          title: "Dashboard",
          icon: "home",
          type: "link",
          path: `${process.env.PUBLIC_URL}/dashboard/default`,
          active: false,
        },
        {
          title: "Users",
          icon: "user",
          path: `${process.env.PUBLIC_URL}/app/users/users-list`,
          type: "link",
          bookmark: true,
          active: false,
        },
        {
          title: "Barcode",
          icon: "bonus-kit",
          path: `${process.env.PUBLIC_URL}/app/barcode/barcode-list`,
          type: "link",
          bookmark: true,
          active: false,
        },
        {
          title: "DSP",
          icon: "bonus-kit",
          path: `${process.env.PUBLIC_URL}/app/dsp/dsp-list`,
          type: "link",
          bookmark: true,
          active: false,
        },
        {
          title: "Labels",
          icon: "to-do",
          type: "link",
          bookmark: true,
          active: false,
          path: `${process.env.PUBLIC_URL}/app/labels/admin-labels`,
        },

        {
          title: "Releases",
          icon: "project",
          type: "sub",

          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/releases/catalog`,
              type: "link",
              title: "Catalog",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/new-catalog`,
              type: "link",
              title: "Build Product",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/import`,
              type: "link",
              title: "ImportRelease",
            },
          ],
        },

        /* {
          title: "DDEX",
          icon: "widget",
          type: "sub",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/widgets/general`,
              title: "General",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/widgets/chart`,
              title: "Chart",
              type: "link",
            },
          ],
        }, */
       /*  {
          title: "Analitycs",
          icon: "charts",
          type: "link",
          path: `${process.env.PUBLIC_URL}/app/analytics/manage`,
          active: false,
        }, */
/* 
        {
          title: "Invoices",
          icon: "form",
          path: `${process.env.PUBLIC_URL}/app/invoices/invoices-list`,
          type: "link",
          active: false,
        }, */
        // {
        //   title: "Tools",
        //   icon: "board",
        //   type: "sub",
        //   bookmark: true,
        //   active: false,
        //   children: [
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/typography`, title: "Typography", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/avatar`, title: "Avatar", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/helper-class`, title: "Helper-Class", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/grids`, title: "Grids", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills`, title: "Tag-Pills", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/progress`, title: "Progress", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/modal`, title: "Modal", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/alert`, title: "Alert", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/popover`, title: "Popover", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/tooltips`, title: "Tooltips", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/spinner`, title: "Spinner", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/dropdown`, title: "Dropdown", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/accordion`, title: "Accordion", type: "link" },
        //     {
        //       title: "Tabs",
        //       type: "sub",
        //       children: [
        //         { title: "Bootstrap", type: "link", path: `${process.env.PUBLIC_URL}/ui-kits/tabs/bootstrap` },
        //         { title: "Line", type: "link", path: `${process.env.PUBLIC_URL}/ui-kits/tabs/line` },
        //       ],
        //     },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/shadow`, title: "Shadow", type: "link" },
        //     { path: `${process.env.PUBLIC_URL}/ui-kits/list`, title: "List", type: "link" },
        //   ],
        // },


/*         {
          title: "Accounting Management",
          icon: "board",
          type: "link",
          bookmark: true,
          active: false,
          path: `${process.env.PUBLIC_URL}/app/accounting`,
        },
        {
          title: "Expenses",
          icon: "board",
          type: "link",
          bookmark: true,
          active: false,
          path: `${process.env.PUBLIC_URL}/app/expenses/contrib-expenses`,
        }, */
        /* {
          title: "Royalties",
          icon: "board",
          type: "link",
          bookmark: true,
          active: false,
          path: `${process.env.PUBLIC_URL}/app/royalties`,
        } */
      ],
    },
  ],
  artist: [
    {
      menutitle: "Artist",
      menucontent: "Dashboards,Users,Labels,Releases,Transfer,Smart Link,Analitycs",
      Items: [
        {
          title: "Dashboard",
          icon: "home",
          type: "link",
          path: `${process.env.PUBLIC_URL}/dashboard/default`,
          active: false,
        },
       /*  {
          title: "Team",
          icon: "user",
          path: `${process.env.PUBLIC_URL}/app/users/user-list`,
          type: "sub",
          bookmark: true,
          active: false,
          children: [
            { path: `${process.env.PUBLIC_URL}/app/artist/artist-list`, type: "link", title: "Manage" },
           
          ],
        }, */
        {
          title: "Releases",
          icon: "project",
          type: "sub",

          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/releases/catalog`,
              type: "link",
              title: "Catalog",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/new-catalog`,
              type: "link",
              title: "Build Product",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/import`,
              type: "link",
              title: "ImportRelease",
            },
          ],
        },
        {
          title: "Transfer",
          icon: "button",
          type: "link",
          active: false,
          path: `${process.env.PUBLIC_URL}/app/transfer`,
        },
        {
          title: "Smart Link",
          icon: "button",
          type: "link",
          active: false,
          path: `${process.env.PUBLIC_URL}/app/geniuslink`,
        },
        {
          title: "Accounting",
          icon: "board",
          type: "link",
          bookmark: true,
          active: false,
          path: `${process.env.PUBLIC_URL}/app/accounting`,
        },
        {
          title: "Invoices",
          type: "link",
          icon: "form",
          path: `${process.env.PUBLIC_URL}/app/invoices/invoices-list`,
          active: false,
        },
        {
          title: "Royalties",
          icon: "board",
          type: "link",
          bookmark: true,
          active: false,
          path: `${process.env.PUBLIC_URL}/app/royalties`,
        }
      ],
    },
  ],
  staff: [
    {
      menutitle: "Staff",
      menucontent: "Dashboards,Users,Labels,Releases,Transfer,Smart Link,Analitycs",
      Items: [
        {
          title: "Dashboard",
          icon: "home",
          type: "link",
          path: `${process.env.PUBLIC_URL}/dashboard/default`,
          active: false,
        },
        {
          title: "Users",
          icon: "user",
          path: `${process.env.PUBLIC_URL}/app/users/user-list`,
          type: "sub",
          bookmark: true,
          active: false,
          children: [
            { path: `${process.env.PUBLIC_URL}/app/users/users-list`, type: "link", title: "Manage" },
            { path: `${process.env.PUBLIC_URL}/app/project/new-project`, type: "link", title: "Permissions" },
          ],
        },
        {
          title: "Releases",
          icon: "project",
          type: "sub",

          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/releases/catalog`,
              type: "link",
              title: "Catalog",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/new-catalog`,
              type: "link",
              title: "Build Product",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/import`,
              type: "link",
              title: "ImportRelease",
            },
          ],
        },
        {
          title: "Transfer",
          icon: "button",
          type: "link",
          active: false,
          path: `${process.env.PUBLIC_URL}/app/transfer`,
        },
        {
          title: "Smart Link",
          icon: "button",
          type: "link",
          active: false,
          path: `${process.env.PUBLIC_URL}/app/geniuslink`,
        },
        {
          title: "Analitycs",
          icon: "charts",
          path: `${process.env.PUBLIC_URL}/app/analytics/manage`,
          active: false,
          type: "link",
          children: [
            { path: `${process.env.PUBLIC_URL}/charts/apex`, type: "link", title: "Apex" },
            { path: `${process.env.PUBLIC_URL}/charts/google`, type: "link", title: "Google" },
            { path: `${process.env.PUBLIC_URL}/charts/chartjs`, type: "link", title: "Chartjs" },
          ],
        },

      ],
    },
  ],
  label: [
    {
      menutitle: "Label",
      menucontent: "Dashboards,Users,Labels,Releases,Analitycs,Accounting",
      Items: [
        {
          title: "Dashboard",
          icon: "home",
          type: "link",
          path: `${process.env.PUBLIC_URL}/dashboard/default`,
          active: false,
        },
        {
          title: "Users",
          icon: "user",
          path: `${process.env.PUBLIC_URL}/app/labels/labels-list`,
          type: "sub",
          bookmark: true,
          active: false,
          children: [
            { path: `${process.env.PUBLIC_URL}/app/labels/labels-list`, type: "link", title: "Manage" },
            // { path: `${process.env.PUBLIC_URL}/app/labels/new-project`, type: "link", title: "Permissions" },
          ],
        },
        {
          title: "Releases",
          icon: "project",
          type: "sub",

          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/app/releases/catalog`,
              type: "link",
              title: "Catalog",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/new-catalog`,
              type: "link",
              title: "Build Product",
            },
            {
              path: `${process.env.PUBLIC_URL}/app/releases/import`,
              type: "link",
              title: "ImportRelease",
            },
          ],
        },
        {
          title: "Transfer",
          icon: "button",
          type: "link",
          active: false,
          path: `${process.env.PUBLIC_URL}/app/transfer`,
        },
        {
          title: "Smart Link",
          icon: "button",
          type: "link",
          active: false,
          path: `${process.env.PUBLIC_URL}/app/geniuslink`,
        },
        {
          title: "Analitycs",
          icon: "charts",
          path: `${process.env.PUBLIC_URL}/app/analytics/manage`,
          active: false,
          type: "link",
        },
        {
          title: "Accounting Management",
          icon: "board",
          type: "link",
          bookmark: true,
          active: false,
          path: `${process.env.PUBLIC_URL}/app/accounting`,
        },
        {
          title: "Expenses",
          icon: "board",
          type: "link",
          bookmark: true,
          active: false,
          path: `${process.env.PUBLIC_URL}/app/expenses/contrib-expenses`,
        },
        {
          title: "Invoices",
          type: "link",
          icon: "form",
          path: `${process.env.PUBLIC_URL}/app/invoices/invoices-list`,
          active: false,
        },
      ],
    },
  ],
};
