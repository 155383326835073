import { useQuery } from "@tanstack/react-query";
import AccountingService from '../Services/accounting/AccountingService';

const useAccountingData = (artistId, type, quarter, year, page) => {
    return useQuery({
        queryKey: ['accountingData', artistId, type, quarter, year, page],
        queryFn: () => AccountingService.getDataAccountingArtist(artistId, type, quarter, year, page)
    });
};

const useYoutubeAccountingData = (artistId, type, month, page) => {
    return useQuery({
        queryKey: ['youtubeAccountingData', artistId, type, month, page],
        queryFn: () => AccountingService.getDataYoutubeAccountingArtist(artistId, type, month, page)
    });
};

const useLabelArtists = (userData) => {
    return useQuery({
        queryKey: ["labelArtists", userData],
        queryFn: () => userData ? AccountingService.getArtistsByLabel(userData) : Promise.resolve([]),
        enabled: !!userData
    });
};

export { useAccountingData, useLabelArtists, useYoutubeAccountingData };