// src/utils/customization.js
export async function fetchCustomizationSettings() {
    const origin = window.location.origin;
    try {
        console.log('Origin:', origin);
        // Dump data for testing
        if (origin === 'https://tryred.centrakey.com') {
            return {
                color1: '#FF0000',
                color2: '#00FF00',
                bgImage: '/images/login/x-bg.jpg',
                logo: '/assets/images/logo/x-logo.png'
            };
        } else if (origin === 'https://tryred.artistsync.top') {
            return {
                color1: '#ac76b7',
                color2: '#1b0d31',
                bgImage: 'https://i.ibb.co/5s0FgRZ/Sound-Wave.png',
                logo: 'https://i.ibb.co/6YDvpbH/logo-red.png'
            };
        } else if (origin === 'https://redbackstage.tn') {
            console.log('origin detected', origin);
            return {
                color1: '#D51515',
                color2: '#1b0d31',
                bgImage: 'https://i.ibb.co/rHQt3Q1/login-bg.png',
                logo: 'https://i.ibb.co/mytXnY0/logo-red.png'
            };
        } else {
            return {
                color1: '#D51515',
                color2: '#1b0d31',
                bgImage: 'https://i.ibb.co/rHQt3Q1/login-bg.png',
                logo: 'https://i.ibb.co/mytXnY0/logo-red.png'
            };
        }
    } catch (error) {
        console.error('Error fetching customization settings:', error);
        return {
            color1: '#D51515',
            color2: '#1b0d31',
            bgImage: 'https://i.ibb.co/rHQt3Q1/login-bg.png',
            logo: 'https://i.ibb.co/mytXnY0/logo-red.png'
        };
    }
}