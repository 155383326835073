import React, { useContext, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Row, Col, Input, FormGroup, Form, Label, Badge } from 'reactstrap';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import { useNavigate } from 'react-router';
import CustomizerContext from '../../../_helper/Customizer';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from 'react-datepicker';
import { Download, X, Check, CheckCircle } from 'react-feather';
import GridLoader from '../../Loaders/GridLoader';
import Error503 from '../../Pages/ErrorPages/ErrorPage503';
import InvoiceService from '../../../Services/invoices/InvoiceService';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const statuses = [
  { value: 'draft', label: 'Draft' },
  { value: 'updated_by_label', label: 'Updated by Label' },
  { value: 'finished', label: 'Finished' },
];

const artistStatuses = [
  { value: 'paid', label: 'Paid' },
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'draft', label: 'Draft' },
];



const InvoicesContainer = () => {
  const {layoutURL} = useContext(CustomizerContext);
  const queryClient = useQueryClient();
  const {userData, loading, error} = useAuth();
  const [startDate, setStartDate] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const navigate = useNavigate();
  const color1 = userData?.label?.color1 || "#F9AFAF";
  const color2 = userData?.label?.color2 || "#FF5E6C";
  const [visibility, setVisibility] = useState({});

  const { data: invoices, isLoading: isInvoicesLoading, isError: isInvoicesError } = useQuery({
    queryKey: ['invoices', userData?.role, userData?.role === 'label' ? userData?.label?.label_id : userData?.id_user],
    queryFn: () => InvoiceService.getInvoices(userData?.role === 'label' ? userData?.label?.label_id : userData?.id_user, userData?.role),
    enabled: !!userData?.role,
  });

  const { data: artists, isLoading: isArtistsLoading, isError: isArtistsError } = useQuery({
    queryKey: ['artists', userData?.label?.label_id],
    queryFn: () => InvoiceService.getArtistsByLabel(userData),
    enabled: !!userData?.label?.label_id,
  });

  useEffect(() => {
    if (invoices) {
      let filtered = invoices;

      if (selectedArtist) {
        filtered = filtered.filter(invoice => invoice.artist_id === selectedArtist.value);
      }

      if (selectedStatus) {
        filtered = filtered.filter(invoice => invoice.status === selectedStatus.value);
      }

      if (searchTerm) {
        filtered = filtered.filter(invoice =>
            invoice.artist_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            invoice.month_year.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (startDate) {
        filtered = filtered.filter(invoice => moment(invoice.date).isSame(startDate, 'day'));
      }

      setFilteredInvoices(filtered);
    }
  }, [invoices, selectedArtist, selectedStatus, searchTerm, startDate]);

  const downloadInvoiceMutation = useMutation({
    mutationFn: (invoiceUUID) => InvoiceService.downloadInvoice(invoiceUUID),
    onError: (error) => {
      console.error("Failed to download invoice", error);
    },
  });

  const updateVisibilityMutation = useMutation({
    mutationFn: ({ uuid, visibility }) => InvoiceService.updateInvoiceVisibility(uuid, visibility),
    onSuccess: () => {
      queryClient.invalidateQueries(['invoices', userData?.label?.label_id]);
      toast.success("Visibility changed successfully");
    },
    onError: (error) => {
      console.error("Failed to update invoice visibility", error);
    },
  });

  const updateStatusToFinishedMutation = useMutation({
    mutationFn: ({ uuid, finished }) => InvoiceService.updateInvoiceStatusToFinished(uuid, finished),
    onSuccess: () => {
      queryClient.invalidateQueries(['invoices', userData?.label?.label_id]);
      toast.success("Status updated to finished successfully");
    },
    onError: (error) => {
      console.error("Failed to update status to finished", error);
    },
  });

  const updateStatusToPaidMutation = useMutation({
    mutationFn: ({ uuid, paid }) => InvoiceService.updateInvoiceStatusToPaid(uuid, paid),
    onSuccess: () => {
      queryClient.invalidateQueries(['invoices', userData?.label?.label_id]);
      toast.success("Status updated to paid successfully");
    },
    onError: (error) => {
      console.error("Failed to update status to paid", error);
    },
  });

  const handleDownloadClick = (invoiceUUID) => {
    console.log('Downloading invoice:', invoiceUUID);
    downloadInvoiceMutation.mutate(invoiceUUID);
  };

  const handleVisibilityChange = (row) => {
    const newVisibility = !row.visibility;
    setVisibility(prevState => ({
      ...prevState,
      [row.id]: newVisibility
    }));
    updateVisibilityMutation.mutate({ uuid: row.uuid, visibility: newVisibility });
  };

  const handleStatusFinishedUpdate = (row) => {
    const newStatus = row.finished === 1 ? 0 : 1;
    updateStatusToFinishedMutation.mutate({ uuid: row.uuid, finished: newStatus });
  };

  const handlePaidStatusUpdate = (row) => {
    const newStatus = row.paid === 1 ? 0 : 1;
    updateStatusToPaidMutation.mutate({ uuid: row.uuid, paid: newStatus });
  };

  const tableColumns = [
    { name: 'Artist', selector: row => row['artist_name'], },
    { name: 'Date', selector: row => moment(row['date']).format('DD/MM/YYYY'), },
    { name: 'Month/Year', selector: row => row['month_year'], sortable: true, center: true },
    { name: 'Status', selector: row => row['status'] },
    {
      name: 'Share Type',
      cell: row => (
          row.share_types.map((type, index) => (
              <Badge key={index} color={type === 'youtube' ? 'danger' : 'dark'} className="mr-1">
                {type}
              </Badge>
          ))
      ),
      center: true
    },
    {
      name: 'Visibility',
      cell: row => (
          <Label className="switch">
            <Input
                type="checkbox"
                checked={visibility[row.id] ?? row.visibility}
                onChange={() => handleVisibilityChange(row)}
            />
            <span className="switch-state bg-secondary"></span>
          </Label>
      ),
      center: true
    },
    {
      name: 'Finished',
      cell: row => (
          <Label className="switch">
            <Input
                type="checkbox"
                checked={row.finished === 1}
                onChange={() => handleStatusFinishedUpdate(row)}
            />
            <span className="switch-state bg-secondary"></span>
          </Label>
      ),
      center: true
    },
    {
      name: 'Paid',
      cell: row => (
          <Label className="switch">
            <Input
                type="checkbox"
                checked={row.paid === 1}
                onChange={() => handlePaidStatusUpdate(row)}
            />
            <span className="switch-state bg-secondary"></span>
          </Label>
      ),
      center: true
    },
    {
      name: 'Unpaid Expenses',
      selector: row => row['has_unpaid_expenses'] ? <X style={{ color: 'red' }} /> : <Check style={{ color: 'green' }} />,
      center: true
    },
    {
      name: 'Actions',
      cell: row => (
          <div className="d-flex justify-content-around">
            <Download
                style={{ cursor: row['file_path'] ? 'pointer' : 'not-allowed' }}
                onClick={() => row['file_path'] && handleDownloadClick(row.uuid)}
                disabled={!row['file_path']}
            />
            {!row['has_unpaid_expenses'] && <CheckCircle style={{ cursor: 'pointer', color: 'green' }} />}
          </div>
      ),
      center: true
    }
  ];

  const artistTableColumns = [
    {name: 'Date', selector: row => moment(row['date']).format('DD/MM/YYYY')},
    {name: 'Month/Year', selector: row => row['month_year']},
    {
      name: 'Share Types',
      selector: row => row['share_types'].map(type => (
          <Badge color={type === 'youtube' ? 'danger' : 'dark'} key={type}>
            {type}
          </Badge>
      )),
      center: true
    },
    {
      name: 'Paiment',
      selector: row => (row['status'] === 'paid' ? <Check style={{color: 'green'}}/> : <X style={{color: 'red'}}/>),
      center: true
    },
    {
      name: 'Actions',
      cell: row => (
          <div className="d-flex justify-content-around">
            <Download
                style={{ cursor: row['status'] === 'paid' && row['file_path'] ? 'pointer' : 'not-allowed' }}
                onClick={() => row['status'] === 'paid' && row['file_path'] && handleDownloadClick(row.uuid)}
                disabled={row['status'] !== 'paid' || !row['file_path']}
            />
          </div>
      ),
      center: true
    }
  ];

  if (loading || isInvoicesLoading || isArtistsLoading) {
    return <GridLoader />;
  }

  if (error || isInvoicesError || isArtistsError) {
    return <Error503 />;
  }

  const artistOptions = artists.map(artist => ({ value: artist.id, label: artist.name }));

  const handleArtistChange = (selectedOption) => {
    setSelectedArtist(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleRowClick = (row) => {
    navigate(
        `${process.env.PUBLIC_URL}/app/invoices/invoice-details/${row.uuid}/${layoutURL}`
    );
  };

  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: userData?.label?.color1 || '#f5f5f5',
        },
      },
    },
  };

  return (
      <>
        <Breadcrumbs mainTitle='Invoices' parent='Invoices' title='Invoices' buttons={[]} />
        <Card>
          <CardHeader>
            <div className='header-top'>
              <H5 attrH5={{ className: 'm-0' }}>{"Invoices Table"}</H5>
              <div className='card-header-right-icon'>
                <Form className="theme-form">
                  <Row className="align-items-center">
                    {userData?.role !== 'artist' && (
                        <Col>
                          <FormGroup className="form-group row">
                            <Col xl='12' sm='12'>
                              <Select
                                  onChange={handleArtistChange}
                                  options={artistOptions}
                                  placeholder="Filter By artist..."
                                  isClearable
                                  components={makeAnimated()}
                                  closeMenuOnSelect={true}
                                  styles={{
                                    control: (styles, { isFocused, menuIsOpen }) => ({
                                      ...styles,
                                      borderColor: isFocused ? color1 : "#ced4da",
                                      "&:hover": { borderColor: color1 },
                                      ...(menuIsOpen && { borderColor: color2 }),
                                      width: "100%",
                                      fontSize: "13px",
                                    }),
                                    menu: (styles) => ({ ...styles }),
                                    option: (styles, { isFocused, isSelected }) => ({
                                      ...styles,
                                      backgroundColor: isSelected ? color1 : isFocused ? color1 : null,
                                      color: '#00000',
                                    }),
                                    singleValue: (styles) => ({ ...styles, color: color2 }),
                                  }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                    )}
                    <Col>
                      <FormGroup className="form-group row">
                        <Col xl='12' sm='12'>
                          <Select
                              onChange={handleStatusChange}
                              options={userData?.role === 'artist' ? artistStatuses : statuses}
                              placeholder="Filter By status..."
                              isClearable
                              components={makeAnimated()}
                              closeMenuOnSelect={true}
                              styles={{
                                control: (styles, { isFocused, menuIsOpen }) => ({
                                  ...styles,
                                  borderColor: isFocused ? color1 : "#ced4da",
                                  "&:hover": { borderColor: color1 },
                                  ...(menuIsOpen && { borderColor: color2 }),
                                  width: "100%",
                                  fontSize: "13px",
                                }),
                                menu: (styles) => ({ ...styles }),
                                option: (styles, { isFocused, isSelected }) => ({
                                  ...styles,
                                  backgroundColor: isSelected ? color1 : isFocused ? color1 : null,
                                  color: '#00000',
                                }),
                                singleValue: (styles) => ({ ...styles, color: "#7F3333" }),
                              }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="form-group row">
                        <Col xl='12' sm='12'>
                          <div className="input-group">
                            <DatePicker
                                className="form-control digits"
                                selected={startDate}
                                onChange={handleDateChange}
                                placeholderText="Filter By Date"
                                style={{ width: '100%', fontSize: '13px' }}
                            />
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="form-group row">
                        <Col xl='12' sm='12'>
                          <Input
                              type="text"
                              className='form-control'
                              placeholder="Search..."
                              onChange={handleSearchChange}
                              style={{ width: '100%', fontSize: '13px' }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="table-responsive support-table">
              <DataTable
                  columns={userData?.role === 'artist' ? artistTableColumns : tableColumns}
                  data={filteredInvoices}
                  striped={false}
                  center={true}
                  customStyles={userData?.role !== 'artist' ? customStyles : undefined}
                  onRowClicked={userData?.role !== 'artist' ? handleRowClick : undefined}
                  pagination
              />
            </div>
          </CardBody>
        </Card>
      </>
  );
}

export default InvoicesContainer;