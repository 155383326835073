import React, { useState } from "react";
import { FormGroup, Label, Col, Input, Media, Row } from "reactstrap";
import CustomizerContext from "../../../../_helper/Customizer";



const ArtistFields = ({ register, errors, isAdmin }) => {
  const [artistHasAnalytics, setArtistHasAnalytics] = useState(null);
  const { mix_background_layout} = React.useContext(CustomizerContext);

  const textColorClass = 'text-dark';
  const handleAnalyticsChange = (value) => {
    setArtistHasAnalytics(value);
  };
  return (
      <>
        <FormGroup className="row mb-3 mt-4">
          <Col sm="4">
            <Label className="col-form-label"><span className={textColorClass}>Facebook</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Facebook Link"
                {...register("facebook")}
            />
            {errors.facebook && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.facebook.message}
                </p>
            )}
          </Col>
          <Col sm="4">
            <Label className="col-form-label"> <span className={textColorClass}>X</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="X Link"
                {...register("x")}
            />
            {errors.x && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.x.message}
                </p>
            )}
          </Col>
          <Col sm="4">
            <Label className="col-form-label"> <span className={textColorClass}>Instagram</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Instagram Link"
                {...register("instagram")}
            />
            {errors.instagram && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.instagram.message}
                </p>
            )}
          </Col>
        </FormGroup>
        <FormGroup className="row mb-3">
          <Col sm="4">
            <Label className="col-form-label"> <span className={textColorClass}>Deezer</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Deezer Link"
                {...register("deezer")}
            />
            {errors.deezer && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.deezer.message}
                </p>
            )}
          </Col>
          <Col sm="4">
            <Label className="col-form-label"> <span className={textColorClass}>Apple Music</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Apple Music Link"
                {...register("appleMusic")}
            />
            {errors.appleMusic && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.appleMusic.message}
                </p>
            )}
          </Col>
          <Col sm="4">
            <Label className="col-form-label"> <span className={textColorClass}>Youtube</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Youtube Link"
                {...register("youtube")}
            />
            {errors.youtube && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.youtube.message}
                </p>
            )}
          </Col>
        </FormGroup>
        <FormGroup className="row mb-3">
          <Col sm="4">
            <Label className="col-form-label"><span className={textColorClass}>Soundcloud</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Soundcloud Link"
                {...register("soundcloud")}
            />
            {errors.soundcloud && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.soundcloud.message}
                </p>
            )}
          </Col>
          <Col sm="4">
            <Label className="col-form-label"><span className={textColorClass}>Anghami</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Anghami Link"
                {...register("anghami")}
            />
            {errors.anghami && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.anghami.message}
                </p>
            )}
          </Col>
          <Col sm="4">
            <Label className="col-form-label"><span className={textColorClass}>Tiktok</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Tiktok Link"
                {...register("tiktok")}
            />
            {errors.tiktok && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.tiktok.message}
                </p>
            )}
          </Col>
        </FormGroup>
        <FormGroup className="row mb-3">
          <Col sm="4">
            <Label className="col-form-label"><span className={textColorClass}>Spotify</span></Label>
            <input
                className="form-control"
                type="text"
                placeholder="Spotify Link"
                {...register("spotify")}
            />
            {errors.spotify && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {errors.spotify.message}
                </p>
            )}
          </Col>
          {isAdmin && (
              <Col sm="4">
                <Label className="col-form-label"><span className={textColorClass}>Hidden Facebook</span></Label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Hidden Facebook Link"
                    {...register("hidden_facebook")}
                />
                {errors.hidden_facebook && (
                    <p style={{ color: "red", marginTop: "5px" }}>
                      {errors.hidden_facebook.message}
                    </p>
                )}
              </Col>
          )}
          {isAdmin && (
              <Col sm="4">
                <Label className="col-form-label"><span className={textColorClass}>Hidden X</span></Label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Hidden X Link"
                    {...register("hidden_x")}
                />
                {errors.hidden_x && (
                    <p style={{ color: "red", marginTop: "5px" }}>
                      {errors.hidden_x.message}
                    </p>
                )}
              </Col>
          )}
        </FormGroup>
        {isAdmin && (
            <FormGroup className="row mb-3">
              <Col sm="12">
                <Row className="align-items-center mt-4">
                  <Col xs="6" className="col-form-label">
                    <Label><span className={textColorClass}>Does this artist have analytics?</span></Label>
                  </Col>
                  <Col xs="3" className="d-flex align-items-center">
                    <div className="radio radio-primary">
                      <input
                          type="radio"
                          name="analytics"
                          id="analytics1"
                          value="true"
                          checked={artistHasAnalytics === "true"}
                          onChange={() => handleAnalyticsChange("true")}
                      />
                      <Label for="analytics1"><span className={textColorClass}>Yes</span></Label>
                    </div>
                  </Col>
                  <Col xs="3" className="d-flex align-items-center">
                    <div className="radio radio-primary">
                      <input
                          type="radio"
                          name="analytics"
                          id="analytics2"
                          value="false"
                          checked={artistHasAnalytics === "false"}
                          onChange={() => handleAnalyticsChange("false")}
                      />
                      <Label for="analytics2"><span className={textColorClass}>No</span></Label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
        )}
      </>
  );
};

export default ArtistFields;
