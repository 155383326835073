import axiosAccounting from "../../api/accounting";

const InvoiceService = {
    getLabelInvoices: async (labelId) => {
        try {
            const response = await axiosAccounting.get(`/label-invoices/${labelId}`);
            return response.data.invoices;
        } catch (error) {
            console.error("Failed to fetch invoices", error);
            throw error;
        }
    },
    getInvoices: async (id, type) => {
        try {
            const response = await axiosAccounting.get(`/invoices/${id}/${type}`);
            return response.data.invoices;
        } catch (error) {
            console.error("Failed to fetch invoices", error);
            throw error;
        }
    },
    getArtistsByLabel: async (user) => {
        try {
            const labelId = user.label.label_id;
            const response = await axiosAccounting.get(`/labels/${labelId}/artists`);
            if (!response.data.artists) {
                throw new Error('Failed to fetch artists');
            }
            return response.data.artists;
        } catch (error) {
            console.error("Failed to fetch artists", error);
            return [];
        }
    },
    getInvoiceByUUID: async (uuid) => {
        try {
            const response = await axiosAccounting.get(`/invoiceData/${uuid}`);
            return response.data;
        } catch (error) {
            console.error("Failed to fetch invoice by UUID", error);
            throw error;
        }
    },

    downloadInvoice: async (invoiceUUID) => {
        try {
            const response = await axiosAccounting.get(`/invoices/download/${invoiceUUID}`, {
                responseType: 'blob', 
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice_${invoiceUUID}.pdf`); 
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Failed to download invoice", error);
            throw error;
        }
    },

    updateInvoice: async (uuid, selectedExpenses) => {
        const response = await axiosAccounting.post(`/invoices/updateInvoice/${uuid}`, {
            selected_expenses: selectedExpenses
        });
        return response.data;
    },

    updateInvoiceVisibility: async (uuid, visibility) => {
        try {
            const response = await axiosAccounting.patch(`/invoices/${uuid}/visibility`, { visibility });
            return response.data;
        } catch (error) {
            console.error("Failed to update invoice visibility", error);
            throw error;
        }
    },

    updateInvoiceStatusToFinished: async (uuid, finished) => {
        try {
            const response = await axiosAccounting.patch(`/invoices/${uuid}/status/finished`, { finished });
            return response.data;
        } catch (error) {
            console.error("Failed to update invoice status to finished", error);
            throw error;
        }
    },

    updateInvoiceStatusToPaid: async (uuid, paid) => {
        try {
            const response = await axiosAccounting.patch(`/invoices/${uuid}/status/paid`, { paid });
            return response.data;
        } catch (error) {
            console.error("Failed to update invoice status to paid", error);
            throw error;
        }
    },




};

export default InvoiceService;