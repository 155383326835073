import axiosAccounting from "../../api/accounting";

const AccountingService = {
    getDataAccountingArtist: async (artistId, type, quarter, year, page = 1) => {
        try {
            const response = await axiosAccounting.post('/get_data_accounting_artist', {
                artistid: artistId,
                type: type,
                quarter: quarter,
                year: year,
                page: page
            });
            return response.data;
        } catch (error) {
            console.error("Failed to fetch accounting data", error);
            throw error;
        }
    },
    getDataYoutubeAccountingArtist: async (artistId, type, month, page = 1) => {
        try {
            const response = await axiosAccounting.post('/get_data_youtube_accounting_artist', {
                artistid: artistId,
                type: type,
                month: month,
                page: page
            });
            return response.data;
        } catch (error) {
            console.error("Failed to fetch YouTube accounting data", error);
            throw error;
        }
    },
    getArtistsByLabel: async (user) => {
        try {
            const labelId = user.label.label_id;
            const response = await axiosAccounting.get(`/labels/${labelId}/artists`);
            if (!response.data.artists) {
                throw new Error('Failed to fetch artists');
            }
            return response.data.artists;
        } catch (error) {
            console.error("Failed to fetch artists", error);
            return [];
        }
    },
};

export default AccountingService;