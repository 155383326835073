import React from "react";
import { hourglass } from "ldrs";
export default function HourGlassLoader() {
  hourglass.register();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "3vh",
      }}
    >
      <l-hourglass
        size="40"
        bg-opacity="0.1"
        speed="1.75"
        color="black"
      ></l-hourglass>
    </div>
  );
}

// Default values shown
